import React, { useState, useEffect, useRef } from "react";
import { DataTable } from "primereact/datatable";
import { FilterMatchMode, FilterOperator } from "primereact/api";
import { InputText } from "primereact/inputtext";
import { IconField } from "primereact/iconfield";
import { InputIcon } from "primereact/inputicon";
import { Column } from "primereact/column";
import { Row, Col } from "react-bootstrap";
import { Messages } from "primereact/messages";

import axios from "axios";
import AsdaLogo from "../Assests/ASDA.png";
import MorrisonsLogo from "../Assests/Morrisons.png";
import SainsburyLogo from "../Assests/Sainsbury.png";
import TescoLogo from "../Assests/Tesco.png";
import WaitRoseLogo from "../Assests/WaitRose.png";
import OcadoLogo from "../Assests/Ocado.png";
import CoOpLogo from "../Assests/CoOp.png";
import AmazonLogo from "../Assests/Amazon.png";

import { ProgressSpinner } from "primereact/progressspinner";
import { Toast } from "primereact/toast";

import { Button } from "primereact/button";
import { Dialog } from "primereact/dialog";

import { Dropdown } from "primereact/dropdown";
import { InputNumber } from "primereact/inputnumber";
import { useNavigate } from "react-router-dom";
import { MultiSelect } from "primereact/multiselect";

import Navbar from "../Navbar/navbar";

import "./dashboard.css";

let addProductArray = [];
let wrongUrlArray = [],
  sipSiteLoginDetailsLSObj = "";

export default function DashboardNew() {
  const toast = useRef(null);
  const msgs = useRef(null);

  const navigate = useNavigate();

  const [products, setProducts] = useState([]);
  const [editProducts, setEditProducts] = useState([]);

  const [reRender, setReRender] = useState(false);

  const [filters, setFilters] = useState({
    global: { value: null, matchMode: FilterMatchMode.CONTAINS },
    category: { value: null, matchMode: FilterMatchMode.IN },
    productName: { value: null, matchMode: FilterMatchMode.CONTAINS },
    size: { value: null, matchMode: FilterMatchMode.CONTAINS },
  });
  const [editFilters, setEditFilters] = useState({
    global: { value: null, matchMode: FilterMatchMode.CONTAINS },
  });
  const [globalFilterValue, setGlobalFilterValue] = useState("");
  const [editGlobalFilterValue, setEditGlobalFilterValue] = useState("");

  const [addProductDialogBoxvisible, setAddProductDialogBoxvisible] =
    useState(false);
  const [editProductDialogBoxvisible, setEditProductDialogBoxvisible] =
    useState(false);
  const [deleteProductDialog, setDeleteProductDialog] = useState(false);
  const [editProductDialog, setEditProductDialog] = useState(false);
  const [editProductTag, setEditProductTag] = useState(0);
  const [editProductNo, setEditProductNo] = useState(0);

  const [productName, setProductName] = useState("");
  const [productToDelete, setProductToDelete] = useState("");
  const [selectedEditProduct, SetSelectedEditProduct] = useState("");

  const [value1, setValue1] = useState(null);
  const category = [
    { name: "Gin", code: "Gin" },
    { name: "Rum", code: "Rum" },
    { name: "Cider", code: "Cider" },
    { name: "Beer", code: "Beer" },
  ];

  const categoryList = [
    { name: "Gin", code: "Gin" },
    { name: "Rum", code: "Rum" },
    { name: "Cider", code: "Cider" },
    { name: "Beer", code: "Beer" },
  ];

  const [representatives] = useState([
    { name: "Amy Elsner", image: "amyelsner.png" },
    { name: "Beer", image: "beer.png" },
    { name: "Gin", image: "gin.png" },
    { name: "Rum", image: "rum.png" },
    { name: "Cider", image: "cider.png" },
  ]);

  const [selectedCategory, setSelectedCategory] = useState(category[0]);

  const measurements = [
    { name: "Liter", code: "Liter" },
    { name: "Ml", code: "Ml" },
    { name: "Cl", code: "Cl" },
  ];
  const [selectedMeasurement, setSelectedMeasurement] = useState(
    measurements[0]
  );

  const [quantity, setQuantity] = useState(1);

  //Shop URL - START

  const [asdaUrl, setAsdaUrl] = useState("");
  const [morrisonsaUrl, setMorrisonsUrl] = useState("");
  const [sainsburysUrl, setSainsburysUrl] = useState("");
  const [tescoUrl, setTescoUrl] = useState("");
  const [ocadoUrl, setOcadoUrl] = useState("");
  const [coopUrl, setCoopUrl] = useState("");
  const [waitRoseUrl, setWaitRoseUrl] = useState("");
  const [amazonUrl, setAmazonUrl] = useState("");

  const [webLoadingGif, setWebLoadingGif] = useState(false);

  const [addButtonClicked, setAddButtonClicked] = useState(false);
  const [updateButtonClicked, setUpdateButtonClicked] = useState(false);

  //Shop URL - ENDl̥

  useEffect(() => {
    //sipSiteLoginDetailsLS
    let sipSiteLoginDetailsLSString = localStorage.getItem(
      "sipSiteLoginDetails"
    );

    console.log(sipSiteLoginDetailsLSString, "**& USE");
    if (sipSiteLoginDetailsLSString != "undefined") {
      sipSiteLoginDetailsLSObj = JSON.parse(sipSiteLoginDetailsLSString);
    }

    axios.get("getGridData").then((response) => {
      response.data.map((i, idx) => {
        i.category = { name: i.category, code: i.category };
      });
      setProducts(response.data);
      setEditProducts(response.data);
      wrongUrlArray = [];
    });
  }, []);

  const commonBody = (rowData, shopName) => {
    return (
      <a
        href={rowData[shopName] != undefined && rowData[shopName].url}
        target="_blank"
        className="priceColumn"
        style={{
          color: rowData.lowestPriceShopNameList.some(
            (item) => item.toLowerCase() === shopName.toLowerCase()
          )
            ? "#2ca32c"
            : "black",
        }}
      >
        {rowData[shopName] != undefined && rowData[shopName].price != 0 ? (
          <>
            <i className="euroSymbol">£</i> {rowData[shopName].price}{" "}
          </>
        ) : (
          "-"
        )}
      </a>
    );
  };

  const asdaPriceBody = (rowData) => {
    return commonBody(rowData, "asda");
  };

  const morrisonsPriceBody = (rowData) => {
    return commonBody(rowData, "morrisons");
  };

  const sainsburyPriceBody = (rowData) => {
    return commonBody(rowData, "sainsburys");
  };

  const tescoPriceBody = (rowData) => {
    return commonBody(rowData, "tesco");
  };

  const waitRosePriceBody = (rowData) => {
    return commonBody(rowData, "waitrose");
  };

  const ocadoPriceBody = (rowData) => {
    return commonBody(rowData, "ocado");
  };

  const coopPriceBody = (rowData) => {
    return commonBody(rowData, "coop");
  };

  const amazonPriceBody = (rowData) => {
    return commonBody(rowData, "amazon");
  };

  const onGlobalFilterChange = (e) => {
    const value = e.target.value;
    let _filters = { ...filters };

    _filters["global"].value = value;

    setFilters(_filters);
    setGlobalFilterValue(value);
  };

  const editOnGlobalFilterChange = (e) => {
    const value = e.target.value;
    let _filters = { ...editFilters };

    _filters["global"].value = value;

    setEditFilters(_filters);
    setEditGlobalFilterValue(value);
  };

  const renderHeader = () => {
    return (
      <div className="dashboardSearchBar">
        <InputText
          value={globalFilterValue}
          onChange={onGlobalFilterChange}
          placeholder="Search"
        />

        {sipSiteLoginDetailsLSObj != null &&
          sipSiteLoginDetailsLSObj != undefined &&
          sipSiteLoginDetailsLSObj.userPrivilege == "admin" && (
            <Button
              label="Add Product"
              onClick={() => setAddProductDialogBoxvisible(true)}
              className="addProductBtn"
              icon="pi pi-plus"
            />
          )}
      </div>
    );
  };

  const renderEditHeader = () => {
    return (
      <div className="dashboardSearchBar">
        <InputText
          value={editGlobalFilterValue}
          onChange={editOnGlobalFilterChange}
          placeholder="Search"
        />
      </div>
    );
  };

  const productUrlOnBlur = (e, shopName, isUpdate) => {
    let userEnteredValue = e.target.value.replace(/ /g, "");

    let existingShopDetail = addProductArray.filter(
      (obj) => obj.shopName == shopName
    );

    addProductArray = addProductArray.filter(
      (obj) => JSON.stringify(obj) !== JSON.stringify(existingShopDetail[0])
    );

    if (
      (userEnteredValue.length !== 0 &&
        userEnteredValue.trim().length !== 0 &&
        userEnteredValue) ||
      isUpdate
    ) {
      let addProductObj = {
        productName: productName,
        category: selectedCategory,
        quantity: quantity,
        measurement: selectedMeasurement.name,
        shopName: shopName,
        url: userEnteredValue,
      };

      addProductArray.push(addProductObj);
    }
    // }
    // else {
    //   console.log(
    //     editProductDialog,
    //     "?? ARRAY",
    //     shopName,
    //     ">>",
    //     selectedEditProduct
    //   );
    //   addProductArray = addProductArray.filter(
    //     (obj) => obj.shopName != shopName
    //   );
    // }
  };

  const productImage = (rowData) => {
    return (
      <div class="image-container">
        <img src={rowData.imageUrl} height={40} width={50}></img>
      </div>
    );
  };

  const addProduct = () => {
    wrongUrlArray = [];
    msgs.current.clear();

    if (productName.trim().length === 0) {
      msgs.current.show([
        {
          sticky: true,
          severity: "error",
          summary: "Product Name is Missing ",
          closable: false,
        },
      ]);
    } else {
      if (addProductArray.length > 0) {
        addProductArray.map((i, idx) => {
          i.productName = productName;
          i.category = selectedCategory.name;
          i.quantity = quantity;
          i.measurement = selectedMeasurement.name;

          switch (i.shopName) {
            case "ASDA":
              if (!i.url.startsWith("https://groceries.asda.com/"))
                wrongUrlArray.push(i.shopName);
              break;

            case "Morrisons":
              if (!i.url.startsWith("https://groceries.morrisons.com/"))
                wrongUrlArray.push(i.shopName);
              break;

            case "Sainsburys":
              if (!i.url.startsWith("https://www.sainsburys.co.uk/"))
                wrongUrlArray.push(i.shopName);
              break;

            case "Tesco":
              if (!i.url.startsWith("https://www.tesco.com/"))
                wrongUrlArray.push(i.shopName);
              break;

            case "WaitRose":
              if (!i.url.startsWith("https://www.waitrose.com/ecom/"))
                wrongUrlArray.push(i.shopName);
              break;

            case "Ocado":
              if (!i.url.startsWith("https://www.ocado.com/"))
                wrongUrlArray.push(i.shopName);
              break;

            case "CoOp":
              if (!i.url.startsWith("https://www.coop.co.uk/"))
                wrongUrlArray.push(i.shopName);
              break;

            case "Amazon":
              if (!i.url.startsWith("https://www.amazon.co.uk/"))
                wrongUrlArray.push(i.shopName);
              break;
          }
        });

        if (wrongUrlArray.length > 0) {
          msgs.current.show([
            {
              sticky: true,
              severity: "error",
              summary: "Wrong Url : ",
              detail: wrongUrlArray.join(", "),
              closable: false,
            },
          ]);
        } else {
          setWebLoadingGif(true);
          setAddButtonClicked(true);

          axios
            .post("/insertProductMasterData", addProductArray, {
              headers: {
                emailId: sipSiteLoginDetailsLSObj.emailId,
              },
            })
            .then((response) => {
              if (response.data) {
                toast.current.show({
                  severity: "success",
                  summary: "Success",
                  detail: "Successfully added",
                  life: 3000,
                });

                axios.get("getGridData").then((response) => {
                  response.data.map((i, idx) => {
                    i.category = { name: i.category, code: i.category };
                  });
                  setProducts(response.data);
                  setEditProducts(response.data);
                  setWebLoadingGif(false);
                });
              }
            })
            .catch((ex) => {
              // alert("401");
              setWebLoadingGif(false);
              toast.current.show({
                severity: "error",
                summary: "401",
                detail: "Authorization Required",
                life: 3000,
              });
            });
        }
      } else {
        toast.current.show({
          severity: "error",

          detail: "Please add Ateast one product URL",
          life: 3000,
        });
      }
    }
  };

  const saveProduct = () => {
    wrongUrlArray = [];
    msgs.current.clear();

    console.log(addProductArray, "##");

    if (productName.trim().length === 0) {
      msgs.current.show([
        {
          sticky: true,
          severity: "error",
          summary: "Product Name is Missing ",
          closable: false,
        },
      ]);
    } else {
      addProductArray.map((i, idx) => {
        console.log(selectedCategory, "!!@!", i);

        i.productName = productName;
        i.category = selectedCategory.name;
        i.quantity = quantity;
        i.measurement = selectedMeasurement.name;
        i.tag = editProductTag;
        i.no = editProductNo;

        if (i.url.trim().length > 0)
          switch (i.shopName) {
            case "ASDA":
              if (!i.url.startsWith("https://groceries.asda.com/"))
                wrongUrlArray.push(i.shopName);
              break;

            case "Morrisons":
              if (!i.url.startsWith("https://groceries.morrisons.com/"))
                wrongUrlArray.push(i.shopName);
              break;

            case "Sainsburys":
              if (!i.url.startsWith("https://www.sainsburys.co.uk/"))
                wrongUrlArray.push(i.shopName);
              break;

            case "Tesco":
              if (!i.url.startsWith("https://www.tesco.com/"))
                wrongUrlArray.push(i.shopName);
              break;

            case "WaitRose":
              if (!i.url.startsWith("https://www.waitrose.com/ecom/"))
                wrongUrlArray.push(i.shopName);
              break;

            case "Ocado":
              if (!i.url.startsWith("https://www.ocado.com/"))
                wrongUrlArray.push(i.shopName);
              break;

            case "CoOp":
              if (!i.url.startsWith("https://www.coop.co.uk/"))
                wrongUrlArray.push(i.shopName);
              break;

            case "Amazon":
              if (!i.url.startsWith("https://www.amazon.co.uk/"))
                wrongUrlArray.push(i.shopName);
              break;
          }
      });

      if (wrongUrlArray.length > 0) {
        msgs.current.show([
          {
            sticky: true,
            severity: "error",
            summary: "Wrong Url : ",
            detail: wrongUrlArray.join(", "),
            closable: false,
          },
        ]);
      } else {
        setWebLoadingGif(true);
        setUpdateButtonClicked(true);
        console.log(sipSiteLoginDetailsLSObj.emailId, "??@");
        axios
          .post("/editProductMasterByTag", addProductArray, {
            headers: {
              emailId: sipSiteLoginDetailsLSObj.emailId,
            },
          })
          .then((response) => {
            if (response.data) {
              toast.current.show({
                severity: "success",
                summary: "Success",
                detail: "Successfully added",
                life: 3000,
              });

              console.log(response, "## RESP -0");

              axios.get("getGridData").then((response) => {
                console.log(response, "## RESP -1");
                response.data.map((i, idx) => {
                  i.category = { name: i.category, code: i.category };
                });
                setProducts(response.data);
                setEditProductTag(0);
                setEditProductNo(0);
                console.log(response, "## RESP -2");

                setWebLoadingGif(false);
              });
            } else {
              setWebLoadingGif(false);
            }
          })
          .catch((ex) => {
            // alert("401");
            setWebLoadingGif(false);
            toast.current.show({
              severity: "error",
              summary: "401",
              detail: "Authorization Required",
              life: 3000,
            });
          });
      }
    }
  };

  const header = renderHeader();
  const editHeader = renderEditHeader();

  const closeActualEditProductDialogBox = () => {
    setEditProductDialog(false);
    setEditProductTag(0);
    setEditProductTag(1);

    SetSelectedEditProduct("");
    clearAddProductInputs();
    setUpdateButtonClicked(false);
  };

  const closeProductDialogBox = () => {
    setAddProductDialogBoxvisible(false);
    setAddButtonClicked(true);

    clearAddProductInputs();
  };

  const clearAddProductInputs = () => {
    wrongUrlArray = [];
    msgs.current.clear();
    setAddButtonClicked(false);

    setProductName("");
    setSelectedCategory(category[0]);
    setQuantity(1);
    setSelectedMeasurement(measurements[0]);

    setAsdaUrl("");
    setMorrisonsUrl("");
    setSainsburysUrl("");
    setTescoUrl("");

    setOcadoUrl("");
    setCoopUrl("");
    setWaitRoseUrl("");
    setAmazonUrl("");

    addProductArray = [];
  };

  const closeEditProductDialogBox = () => {
    setEditProductDialogBoxvisible(false);
  };

  const confirmDeleteProduct = (product) => {
    setProductToDelete(product);
    setDeleteProductDialog(true);
  };

  const editProduct = (product) => {
    setEditProductDialog(true);
    SetSelectedEditProduct(product);

    const category = getCategoryByCode(categoryList, product.category);
    const measurement = getCategoryByCode(measurements, product.measurement);

    setEditProductTag(product.tag);
    setEditProductNo(product.no);

    setProductName(product.productName);
    setSelectedCategory(product.category);
    setQuantity(product.quantity);
    setSelectedMeasurement(measurement);

    setAsdaUrl(product.asda != null ? product.asda.url : "");
    setMorrisonsUrl(product.morrisons != null ? product.morrisons.url : "");
    setSainsburysUrl(product.sainsburys != null ? product.sainsburys.url : "");
    setTescoUrl(product.tesco != null ? product.tesco.url : "");

    setWaitRoseUrl(product.waitrose != null ? product.waitrose.url : "");
    setOcadoUrl(product.ocado != null ? product.ocado.url : "");
    setCoopUrl(product.coop != null ? product.coop.url : "");
    setAmazonUrl(product.amazon != null ? product.amazon.url : "");
  };

  // Function to get category by code
  const getCategoryByCode = (arr, code) => {
    return arr.find((category) => category.code === code);
  };

  const hideDeleteProductDialog = () => {
    setTimeout(() => {
      setProductToDelete("");
    }, 1000);

    setDeleteProductDialog(false);
  };

  const actionBodyTemplate = (rowData) => {
    return (
      <div className="editDeleteIconDiv">
        <React.Fragment>
          <Button
            icon="pi pi-pencil"
            rounded
            outlined
            className="mr-2"
            onClick={() => editProduct(rowData)}
          />
          <Button
            icon="pi pi-trash"
            rounded
            outlined
            severity="danger"
            className="deleteIcon"
            onClick={() => confirmDeleteProduct(rowData)}
          />
        </React.Fragment>
      </div>
    );
  };

  const deleteProduct = () => {
    axios
      .get("/hideProductByTag?tag=" + productToDelete.tag, {
        headers: {
          emailId: sipSiteLoginDetailsLSObj.emailId,
        },
      })
      .then((hideProductResponse) => {
        if (hideProductResponse.data) {
          axios.get("getGridData").then((response) => {
            response.data.map((i, idx) => {
              i.category = { name: i.category, code: i.category };
            });
            setProducts(response.data);
            setEditProducts(response.data);
          });

          setDeleteProductDialog(false);
          toast.current.show({
            severity: "success",
            summary: "Successful",
            detail: "Product Deleted",
            life: 3000,
          });
        }
      })
      .catch((ex) => {
        // alert("401");
        setWebLoadingGif(false);
        toast.current.show({
          severity: "error",
          summary: "401",
          detail: "Authorization Required",
          life: 3000,
        });
      });
  };

  const deleteProductDialogFooter = (
    <div className="productDeleteBtnsDiv">
      <React.Fragment>
        <Button
          label="No"
          icon="pi pi-times"
          outlined
          className="deleteNo"
          onClick={hideDeleteProductDialog}
        />
        <Button
          label="Yes"
          icon="pi pi-check"
          severity="danger"
          className="deleteYes"
          onClick={deleteProduct}
        />
      </React.Fragment>
    </div>
  );

  const representativeBodyTemplate = (rowData) => {
    const representative = rowData.category.name;

    return (
      <div className="flex align-items-center gap-2">
        <span>{representative}</span>
      </div>
    );
  };

  const sizeBodyTemplate = (rowData) => {
    const size = rowData.size;

    return (
      <div className="flex align-items-center gap-2">
        <span>{size}</span>
      </div>
    );
  };

  const representativesItemTemplate = (option) => {
    return (
      <div className="flex align-items-center gap-2">
        <span>{option.name}</span>
      </div>
    );
  };

  const sizeItemTemplate = (option) => {
    return (
      <div className="flex align-items-center gap-2">
        <span>{option.name}</span>
      </div>
    );
  };

  const representativeFilterTemplate = (options) => {
    return (
      <React.Fragment>
        <MultiSelect
          value={options.value}
          options={category}
          itemTemplate={representativesItemTemplate}
          onChange={(e) => options.filterCallback(e.value)}
          optionLabel="name"
          placeholder="Choose Categories"
          className="p-column-filter"
        />
      </React.Fragment>
    );
  };

  const sizeFilterTemplate = (options) => {
    return (
      <React.Fragment>
        <label>Quantity</label>
        <InputNumber
          inputId="integeronly"
          value={value1}
          onValueChange={(e) => setValue1(e.value)}
          className="filterQuantityInput"
          placeholder="Enter the quantity"
        />

        <br />

        <label>Measurement</label>
        <Dropdown
          value={options.value}
          onChange={(e) => options.filterCallback(e.value)}
          options={measurements}
          optionLabel="name"
          placeholder="Select a City"
          className="w-full md:w-14rem"
        />
        {/* <MultiSelect
          value={options.value}
          options={measurements}
          itemTemplate={sizeItemTemplate}
          onChange={(e) => options.filterCallback(e.value)}
          optionLabel="name"
          placeholder="Choose Measurement"
          className="p-column-filter"
        /> */}
      </React.Fragment>
    );
  };

  // const signOutBtn = () => {
  //   localStorage.removeItem("sipSiteLoginDetails");
  //   window.location.reload(false);
  // };

  return (
    <div>
      {/* <div className="addProductBtnDiv">
        {console.log(sipSiteLoginDetailsLSObj, "*&* LOG")}

        {sipSiteLoginDetailsLSObj != undefined &&
        sipSiteLoginDetailsLSObj.emailId != "" &&
        sipSiteLoginDetailsLSObj.password != "" ? (
          <div>
            <label>Hi,{sipSiteLoginDetailsLSObj.emailId.split("@")[0]} </label>
            <Button
              label="Sign out"
              onClick={() => signOutBtn()}
              className="addProductBtn"
            />
          </div>
        ) : (
          <Button
            label="Sign in / Sign up"
            onClick={() => navigate("/login")}
            className="addProductBtn"
          />
        )}
      </div> */}

      <Navbar />

      <Toast ref={toast} />

      <Dialog
        header="Add Product"
        visible={addProductDialogBoxvisible}
        style={{ width: "80vw" }}
        onHide={() => {
          closeProductDialogBox();
        }}
      >
        <div
          class="addProductDiv"
          style={{
            visibility: webLoadingGif ? "hidden" : "visible",
          }}
        >
          <div className="addProductMandatoryDiv">
            <Row>
              <Col xl={4} lg={4} md={4} sm={4} xs={4}>
                <label>Product Name</label>
              </Col>
              <Col xl={8} lg={8} md={8} sm={8} xs={8}>
                <InputText
                  value={productName}
                  autoFocus
                  onChange={(e) => setProductName(e.target.value)}
                  className="addProductName"
                  placeholder="Enter product name"
                />
              </Col>
            </Row>
            <br />

            <Row>
              <Col xl={4} lg={4} md={4} sm={4} xs={4}>
                Category
              </Col>
              <Col xl={8} lg={8} md={8} sm={8} xs={8}>
                <Dropdown
                  value={selectedCategory}
                  onChange={(e) => setSelectedCategory(e.value)}
                  options={category}
                  optionLabel="name"
                  placeholder="Select Category"
                  className="addProductDropdown"
                />
              </Col>
            </Row>
            <br />
            <Row>
              <Col xl={4} lg={4} md={4} sm={4} xs={4}>
                Size
              </Col>
              <Col xl={8} lg={8} md={8} sm={8} xs={8}>
                <div className="addProductSizeDiv">
                  <InputNumber
                    inputId="withoutgrouping"
                    value={quantity}
                    onValueChange={(e) => setQuantity(e.value)}
                    useGrouping={false}
                    className="addProductQuantity"
                  />

                  <Dropdown
                    value={selectedMeasurement}
                    onChange={(e) => setSelectedMeasurement(e.value)}
                    options={measurements}
                    optionLabel="name"
                    placeholder="Select Measurement"
                    className="addProductDropdown"
                  />
                </div>
              </Col>
            </Row>
          </div>

          <Row>
            <Col xl={1} lg={1} md={2} sm={2} xs={2}>
              <img
                src={AsdaLogo}
                height={30}
                width={50}
                className="addProductAsdaLogo"
              />
            </Col>
            <Col xl={5} lg={5} md={10} sm={10} xs={10}>
              <div className="productUrlDiv">
                <InputText
                  value={asdaUrl}
                  onChange={(e) => setAsdaUrl(e.target.value)}
                  className="addProductShopUrlTextBox"
                  placeholder="paste the product url"
                  onBlur={(e) => productUrlOnBlur(e, "ASDA", false)}
                />
              </div>
            </Col>

            <Col xl={1} lg={1} md={2} sm={2} xs={2}>
              <img
                src={MorrisonsLogo}
                height={30}
                width={65}
                className="addProductMorrisonsLogo"
              />
            </Col>
            <Col xl={5} lg={5} md={10} sm={10} xs={10}>
              <div className="productUrlDiv">
                <InputText
                  value={morrisonsaUrl}
                  onChange={(e) => setMorrisonsUrl(e.target.value)}
                  className="addProductShopUrlTextBox"
                  placeholder="paste the product url"
                  onBlur={(e) => productUrlOnBlur(e, "Morrisons", false)}
                />
              </div>
            </Col>
          </Row>
          <br />
          <Row>
            <Col xl={1} lg={1} md={2} sm={2} xs={2}>
              <img
                src={SainsburyLogo}
                height={20}
                width={80}
                className="addProductSainsburyLogo"
              />
            </Col>
            <Col xl={5} lg={5} md={10} sm={10} xs={10}>
              <div className="productUrlDiv">
                <InputText
                  value={sainsburysUrl}
                  onChange={(e) => setSainsburysUrl(e.target.value)}
                  className="addProductShopUrlTextBox"
                  placeholder="paste the product url"
                  onBlur={(e) => productUrlOnBlur(e, "Sainsburys", false)}
                />
              </div>
            </Col>

            <Col xl={1} lg={1} md={2} sm={2} xs={2}>
              <img
                src={TescoLogo}
                height={25}
                width={65}
                className="addProductTescoLogo"
              />
            </Col>
            <Col xl={5} lg={5} md={10} sm={10} xs={10}>
              <div className="productUrlDiv">
                <InputText
                  value={tescoUrl}
                  onChange={(e) => setTescoUrl(e.target.value)}
                  className="addProductShopUrlTextBox"
                  placeholder="paste the product url"
                  onBlur={(e) => productUrlOnBlur(e, "Tesco", false)}
                />
              </div>
            </Col>
          </Row>
          <br />
          <Row>
            <Col xl={1} lg={1} md={2} sm={2} xs={2}>
              <img
                src={WaitRoseLogo}
                height={20}
                width={80}
                className="addProductWaitRoseLogo"
              />
            </Col>
            <Col xl={5} lg={5} md={10} sm={10} xs={10}>
              <div className="productUrlDiv">
                <InputText
                  value={waitRoseUrl}
                  onChange={(e) => setWaitRoseUrl(e.target.value)}
                  className="addProductShopUrlTextBox"
                  placeholder="paste the product url"
                  onBlur={(e) => productUrlOnBlur(e, "WaitRose", false)}
                />
              </div>
            </Col>

            <Col xl={1} lg={1} md={2} sm={2} xs={2}>
              <img
                src={OcadoLogo}
                height={20}
                width={80}
                className="addProductOcadoLogo"
              />
            </Col>
            <Col xl={5} lg={5} md={10} sm={10} xs={10}>
              <div className="productUrlDiv">
                <InputText
                  value={ocadoUrl}
                  onChange={(e) => setOcadoUrl(e.target.value)}
                  className="addProductShopUrlTextBox"
                  placeholder="paste the product url"
                  onBlur={(e) => productUrlOnBlur(e, "Ocado", false)}
                />
              </div>
            </Col>
          </Row>
          <br />
          <Row>
            <Col xl={1} lg={1} md={2} sm={2} xs={2}>
              <img
                src={CoOpLogo}
                height={40}
                width={70}
                style={{ marginLeft: "-15px" }}
                className="addProductCoOpLogo"
              />
            </Col>
            <Col xl={5} lg={5} md={10} sm={10} xs={10}>
              <div className="productUrlDiv">
                <InputText
                  value={coopUrl}
                  onChange={(e) => setCoopUrl(e.target.value)}
                  className="addProductShopUrlTextBox"
                  placeholder="paste the product url"
                  onBlur={(e) => productUrlOnBlur(e, "CoOp", false)}
                />
              </div>
            </Col>

            {/* <Col xl={1} lg={1} md={2} sm={2} xs={2}>
              <img
                src={AmazonLogo}
                height={40}
                width={70}
                className="addProductAmazonLogo"
              />
            </Col>
            <Col xl={5} lg={5} md={10} sm={10} xs={10}>
              <div className="productUrlDiv">
                <InputText
                  value={amazonUrl}
                  onChange={(e) => setAmazonUrl(e.target.value)}
                  className="addProductShopUrlTextBox"
                  placeholder="paste the product url"
                  onBlur={(e) => productUrlOnBlur(e, "Amazon", false)}
                />
              </div>
            </Col> */}
          </Row>

          <br />

          <div className="addProductAddBtnDiv">
            <Row>
              <Col xl={8} lg={6} md={5} sm={11} xs={11}>
                <div className="wrongUrlMsg">
                  <Messages ref={msgs} />
                </div>
              </Col>

              <Col xl={3} lg={3} md={3} sm={3} xs={3}>
                <div className="addProductDialogBoxButtons">
                  {/* <Button
                    label="Reset"
                    className="addProductAddBtn no-text"
                    onClick={() => clearAddProductInputs()}
                    icon="pi pi-refresh"
                  /> */}

                  <Button
                    label="Reset"
                    className="addProductAddBtn no-text"
                    onClick={() => clearAddProductInputs()}
                    icon="pi pi-refresh"
                  />
                  <Button
                    label="Cancel"
                    className="addProductAddBtn no-text"
                    onClick={() => closeProductDialogBox()}
                    icon="pi pi-times"
                  />
                  <Button
                    label="Add"
                    className="addProductAddBtn no-text"
                    onClick={() => addProduct()}
                    icon="pi pi-plus"
                    disabled={addButtonClicked}
                  />
                </div>
              </Col>
            </Row>
          </div>
        </div>

        {webLoadingGif && (
          <div className="spinnerContainerStyle">
            <center>
              <ProgressSpinner
                style={{ width: "50px", height: "50px" }}
                strokeWidth="8"
                fill="var(--surface-ground)"
                animationDuration=".5s"
              />
            </center>
          </div>
        )}
      </Dialog>

      <Dialog
        header="Edit Product 1"
        visible={editProductDialogBoxvisible}
        style={{ width: "80vw" }}
        onHide={() => {
          closeEditProductDialogBox();
        }}
      >
        <DataTable
          value={editProducts}
          filters={editFilters}
          header={editHeader}
          globalFilterFields={["productName", "category", "size"]}
          className="addProductGrid"
          paginator
          rows={10}
        >
          <Column body={productImage} style={{ width: "5%" }}></Column>
          <Column
            field="productName"
            header="Product Name"
            style={{ width: "30%" }}
          ></Column>

          <Column field="size" header="Size" style={{ width: "10%" }}></Column>
          <Column
            // field="category"
            header="Category"
            style={{ width: "10%" }}
            body={representativeBodyTemplate}
          ></Column>
          {console.log(sipSiteLoginDetailsLSObj, "$$%$")}
          {sipSiteLoginDetailsLSObj != null &&
            sipSiteLoginDetailsLSObj != undefined &&
            sipSiteLoginDetailsLSObj.userPrivilege == "admin" && (
              <Column
                body={actionBodyTemplate}
                exportable={false}
                style={{ width: "10%" }}
              ></Column>
            )}
        </DataTable>
      </Dialog>

      <Dialog
        visible={deleteProductDialog}
        style={{ width: "32rem" }}
        breakpoints={{ "960px": "75vw", "641px": "90vw" }}
        header="Confirm"
        modal
        footer={deleteProductDialogFooter}
        onHide={hideDeleteProductDialog}
      >
        <div className="confirmation-content">
          {
            <Row>
              <Col lg={2} md={2} sm={2}>
                <img
                  src={productToDelete.imageUrl}
                  height={80}
                  width={80}
                ></img>
              </Col>
              <Col>
                Are you sure you want to delete{" "}
                <b>
                  {productToDelete.productName} {productToDelete.size}{" "}
                </b>
                ?
              </Col>
            </Row>
          }
        </div>
      </Dialog>

      {/* EDIT - START*/}

      <Dialog
        header="Edit Product"
        visible={editProductDialog}
        style={{ width: "80vw" }}
        onHide={() => {
          closeActualEditProductDialogBox();
        }}
      >
        <div
          class="addProductDiv"
          style={{
            visibility: webLoadingGif ? "hidden" : "visible",
          }}
        >
          <div className="addProductMandatoryDiv">
            <Row>
              <Col xl={4} lg={4} md={4} sm={4} xs={4}>
                <label>Product Name</label>
              </Col>
              <Col xl={8} lg={8} md={8} sm={8} xs={8}>
                <InputText
                  value={productName}
                  onChange={(e) => setProductName(e.target.value)}
                  className="addProductName"
                  placeholder="Enter product name"
                />
              </Col>
            </Row>
            <br />

            <Row>
              <Col xl={4} lg={4} md={4} sm={4} xs={4}>
                Category
              </Col>
              <Col xl={8} lg={8} md={8} sm={8} xs={8}>
                <Dropdown
                  value={selectedCategory}
                  onChange={(e) => setSelectedCategory(e.value)}
                  options={category}
                  optionLabel="name"
                  placeholder="Select Category"
                  className="addProductDropdown"
                />
              </Col>
            </Row>
            <br />
            <Row>
              <Col xl={4} lg={4} md={4} sm={4} xs={4}>
                Size
              </Col>
              <Col xl={8} lg={8} md={8} sm={8} xs={8}>
                <div className="addProductSizeDiv">
                  <InputNumber
                    inputId="withoutgrouping"
                    value={quantity}
                    onValueChange={(e) => setQuantity(e.value)}
                    useGrouping={false}
                    className="addProductQuantity"
                  />
                  <Dropdown
                    value={selectedMeasurement}
                    onChange={(e) => setSelectedMeasurement(e.value)}
                    options={measurements}
                    optionLabel="name"
                    placeholder="Select Measurement"
                    className="addProductDropdown"
                  />
                </div>
              </Col>
            </Row>
          </div>

          <Row>
            <Col xl={1} lg={1} md={2} sm={2} xs={2}>
              <img
                src={AsdaLogo}
                height={30}
                width={50}
                className="addProductAsdaLogo"
              />
            </Col>
            <Col xl={5} lg={5} md={10} sm={10} xs={10}>
              <div className="productUrlDiv">
                <InputText
                  value={asdaUrl}
                  onChange={(e) => setAsdaUrl(e.target.value)}
                  className="addProductShopUrlTextBox"
                  placeholder="paste the product url"
                  onBlur={(e) => productUrlOnBlur(e, "ASDA", true)}
                />
              </div>
            </Col>

            <Col xl={1} lg={1} md={2} sm={2} xs={2}>
              <img
                src={MorrisonsLogo}
                height={30}
                width={65}
                className="addProductMorrisonsLogo"
              />
            </Col>
            <Col xl={5} lg={5} md={10} sm={10} xs={10}>
              <div className="productUrlDiv">
                <InputText
                  value={morrisonsaUrl}
                  onChange={(e) => setMorrisonsUrl(e.target.value)}
                  className="addProductShopUrlTextBox"
                  placeholder="paste the product url"
                  onBlur={(e) => productUrlOnBlur(e, "Morrisons", true)}
                />
              </div>
            </Col>
          </Row>
          <br />
          <Row>
            <Col xl={1} lg={1} md={2} sm={2} xs={2}>
              <img
                src={SainsburyLogo}
                height={20}
                width={80}
                className="addProductSainsburyLogo"
              />
            </Col>
            <Col xl={5} lg={5} md={10} sm={10} xs={10}>
              <div className="productUrlDiv">
                <InputText
                  value={sainsburysUrl}
                  onChange={(e) => setSainsburysUrl(e.target.value)}
                  className="addProductShopUrlTextBox"
                  placeholder="paste the product url"
                  onBlur={(e) => productUrlOnBlur(e, "Sainsburys", true)}
                />
              </div>
            </Col>

            <Col xl={1} lg={1} md={2} sm={2} xs={2}>
              <img
                src={TescoLogo}
                height={25}
                width={65}
                className="addProductTescoLogo"
              />
            </Col>
            <Col xl={5} lg={5} md={10} sm={10} xs={10}>
              <div className="productUrlDiv">
                <InputText
                  value={tescoUrl}
                  onChange={(e) => setTescoUrl(e.target.value)}
                  className="addProductShopUrlTextBox"
                  placeholder="paste the product url"
                  onBlur={(e) => productUrlOnBlur(e, "Tesco", true)}
                />
              </div>
            </Col>
          </Row>
          <br />
          <Row>
            <Col xl={1} lg={1} md={2} sm={2} xs={2}>
              <img
                src={WaitRoseLogo}
                height={20}
                width={80}
                className="addProductWaitRoseLogo"
              />
            </Col>
            <Col xl={5} lg={5} md={10} sm={10} xs={10}>
              <div className="productUrlDiv">
                <InputText
                  value={waitRoseUrl}
                  onChange={(e) => setWaitRoseUrl(e.target.value)}
                  className="addProductShopUrlTextBox"
                  placeholder="paste the product url"
                  onBlur={(e) => productUrlOnBlur(e, "WaitRose", true)}
                />
              </div>
            </Col>

            <Col xl={1} lg={1} md={2} sm={2} xs={2}>
              <img
                src={OcadoLogo}
                height={20}
                width={80}
                className="addProductOcadoLogo"
              />
            </Col>
            <Col xl={5} lg={5} md={10} sm={10} xs={10}>
              <div className="productUrlDiv">
                <InputText
                  value={ocadoUrl}
                  onChange={(e) => setOcadoUrl(e.target.value)}
                  className="addProductShopUrlTextBox"
                  placeholder="paste the product url"
                  onBlur={(e) => productUrlOnBlur(e, "Ocado", true)}
                />
              </div>
            </Col>
          </Row>
          <br />
          <Row>
            <Col xl={1} lg={1} md={2} sm={2} xs={2}>
              <img
                src={CoOpLogo}
                height={40}
                width={70}
                style={{ marginLeft: "-15px" }}
                className="addProductCoOpLogo"
              />
            </Col>
            <Col xl={5} lg={5} md={10} sm={10} xs={10}>
              <div className="productUrlDiv">
                <InputText
                  value={coopUrl}
                  onChange={(e) => setCoopUrl(e.target.value)}
                  className="addProductShopUrlTextBox"
                  placeholder="paste the product url"
                  onBlur={(e) => productUrlOnBlur(e, "CoOp", true)}
                />
              </div>
            </Col>

            {/* <Col xl={1} lg={1} md={2} sm={2} xs={2}>
              <img
                src={AmazonLogo}
                height={40}
                width={70}
                className="addProductAmazonLogo"
              />
            </Col>
            <Col xl={5} lg={5} md={10} sm={10} xs={10}>
              <div className="productUrlDiv">
                <InputText
                  value={amazonUrl}
                  onChange={(e) => setAmazonUrl(e.target.value)}
                  className="addProductShopUrlTextBox"
                  placeholder="paste the product url"
                  onBlur={(e) => productUrlOnBlur(e, "Amazon", true)}
                />
              </div>
            </Col> */}
          </Row>

          <br />

          <div className="addProductAddBtnDiv">
            <Row>
              <Col xl={9} lg={7} md={6} sm={12} xs={12}>
                <div className="wrongUrlMsg">
                  <Messages ref={msgs} />
                </div>
              </Col>

              <Col xl={3} lg={3} md={3} sm={3} xs={3}>
                <div className="addProductDialogBoxButtons">
                  <Button
                    label="Cancel"
                    className="addProductAddBtn no-text"
                    onClick={() => closeActualEditProductDialogBox()}
                    icon="pi pi-times"
                  />

                  <Button
                    label="Update"
                    className="addProductAddBtn no-text"
                    onClick={() => saveProduct()}
                    icon="pi pi-check"
                    disabled={updateButtonClicked}
                  />
                </div>
              </Col>
            </Row>
          </div>
        </div>

        {webLoadingGif && (
          <div className="spinnerContainerStyle">
            <center>
              <ProgressSpinner
                style={{ width: "50px", height: "50px" }}
                strokeWidth="8"
                fill="var(--surface-ground)"
                animationDuration=".5s"
              />
            </center>
          </div>
        )}
      </Dialog>

      {/* EDIT - END */}

      <DataTable
        value={products}
        filters={filters}
        header={header}
        globalFilterFields={["productName", "category", "size"]}
        className="addProductGrid"
        paginator
        rows={10}
      >
        <Column body={productImage} style={{ width: "5%" }}></Column>

        <Column
          field="productName"
          filter
          header="Product Name"
          style={{ width: "30%" }}
          filterPlaceholder="Search by name"
        ></Column>

        <Column
          header="Size"
          // filterField="size"
          field="size"
          style={{ width: "10%" }}
          showFilterMatchModes={false}
          filterMenuStyle={{ width: "14rem" }}
          filterPlaceholder="Search by size"
          // body={sizeBodyTemplate}
          filter
          // filterElement={sizeFilterTemplate}
        ></Column>

        {/* <Column field="size" header="Size" style={{ width: "10%" }}>
        </Column> */}
        <Column
          header="Category"
          filterField="category"
          style={{ width: "10%" }}
          showFilterMatchModes={false}
          filterMenuStyle={{ width: "14rem" }}
          body={representativeBodyTemplate}
          filter
          filterElement={representativeFilterTemplate}
        ></Column>
        <Column
          header={
            <div>
              <img
                src={AsdaLogo}
                alt="Logo"
                style={{
                  height: "25px",
                  width: "45px",
                  verticalAlign: "middle",
                  marginTop: "-10px",
                }}
              />
            </div>
          }
          style={{ width: "10%" }}
          body={asdaPriceBody}
        ></Column>
        <Column
          field="Morrisons"
          header={
            <div>
              <img
                src={MorrisonsLogo}
                alt="Logo"
                style={{
                  height: "35px",
                  width: "70px",
                  verticalAlign: "middle",
                  marginTop: "-10px",
                }}
              />
            </div>
          }
          style={{ width: "10%" }}
          body={morrisonsPriceBody}
        ></Column>
        <Column
          field="Morrisons"
          header={
            <div>
              <img
                src={SainsburyLogo}
                alt="Logo"
                style={{
                  height: "17px",
                  width: "75px",
                  verticalAlign: "middle",
                  marginTop: "3px",
                }}
              />
            </div>
          }
          style={{ width: "10%" }}
          body={sainsburyPriceBody}
        ></Column>
        <Column
          field="Morrisons"
          header={
            <div>
              <img
                src={TescoLogo}
                alt="Logo"
                style={{
                  height: "20px",
                  width: "60px",
                  verticalAlign: "middle",
                }}
              />
            </div>
          }
          style={{ width: "10%" }}
          body={tescoPriceBody}
        ></Column>
        <Column
          field="WaitRose"
          header={
            <div>
              <img
                src={WaitRoseLogo}
                alt="Logo"
                style={{
                  height: "17px",
                  width: "70px",
                  verticalAlign: "middle",
                }}
              />
            </div>
          }
          style={{ width: "10%" }}
          body={waitRosePriceBody}
        ></Column>
        <Column
          field="Ocado"
          header={
            <div>
              <img
                src={OcadoLogo}
                alt="Logo"
                style={{
                  height: "17px",
                  width: "70px",
                  verticalAlign: "middle",
                }}
              />
            </div>
          }
          style={{ width: "10%" }}
          body={ocadoPriceBody}
        ></Column>
        <Column
          field="CoOp"
          header={
            <div>
              <img
                src={CoOpLogo}
                alt="Logo"
                style={{
                  height: "40px",
                  width: "70px",
                  verticalAlign: "middle",
                }}
              />
            </div>
          }
          style={{ width: "10%" }}
          body={coopPriceBody}
        ></Column>
        {/* <Column
          field="Amazon"
          header={
            <div>
              <img
                src={AmazonLogo}
                alt="Logo"
                style={{
                  height: "30px",
                  width: "50px",
                  verticalAlign: "middle",
                }}
              />
            </div>
          }
          style={{ width: "10%" }}
          body={amazonPriceBody}
        ></Column> */}
        {console.log(sipSiteLoginDetailsLSObj, "$$%$")}
        {sipSiteLoginDetailsLSObj != null &&
          sipSiteLoginDetailsLSObj != undefined &&
          sipSiteLoginDetailsLSObj.userPrivilege == "admin" && (
            <Column
              body={actionBodyTemplate}
              exportable={false}
              style={{ width: "10%" }}
            ></Column>
          )}
      </DataTable>
    </div>
  );
}
