import React, { useState, useEffect, useRef } from "react";
import { Row, Col } from "react-bootstrap";
import { Button } from "primereact/button";
import { useNavigate } from "react-router-dom";
import "./navbar.css";

let sipSiteLoginDetailsLSObj = "";
export default function Navbar() {
  const navigate = useNavigate();

  useEffect(() => {
    //sipSiteLoginDetailsLS
    let sipSiteLoginDetailsLSString = localStorage.getItem(
      "sipSiteLoginDetails"
    );

    console.log(sipSiteLoginDetailsLSString, "*& USE");
    if (sipSiteLoginDetailsLSString != "undefined") {
      sipSiteLoginDetailsLSObj = JSON.parse(sipSiteLoginDetailsLSString);
    }
  }, []);

  const signOutBtn = () => {
    localStorage.removeItem("sipSiteLoginDetails");
    window.location.reload(false);
    // setReRender(true);
  };

  return (
    <div>
      <div className="addProductBtnDiv">
        {console.log(sipSiteLoginDetailsLSObj, "*&* LOG")}

        {sipSiteLoginDetailsLSObj != undefined &&
        sipSiteLoginDetailsLSObj.emailId != undefined &&
        sipSiteLoginDetailsLSObj.emailId != "" &&
        sipSiteLoginDetailsLSObj.password != undefined &&
        sipSiteLoginDetailsLSObj.password != "" ? (
          <div>
            {console.log(
              sipSiteLoginDetailsLSObj.emailId.split("@")[0],
              "**/* NAV"
            )}
            <label className="navrbar_userName">
              Hi, {sipSiteLoginDetailsLSObj.emailId.split("@")[0]}{" "}
            </label>
            <Button
              label="Sign out"
              // onClick={() => navigate("/signinpage")}
              onClick={() => signOutBtn()}
              //   className="addProductBtn"
              className="signOutBtn"
              icon="pi pi-sign-out"
            />
          </div>
        ) : (
          <Button
            label="Sign in / Sign up"
            // onClick={() => navigate("/signinpage")}
            onClick={() => navigate("/login")}
            // className="addProductBtn"
            className="signInSignOutBtn"
            icon="pi pi-sign-in"
          />
        )}
      </div>
    </div>
  );
}
