import React, { useEffect, useRef, useState } from "react";
import { useForm, Controller } from "react-hook-form";
import { InputText } from "primereact/inputtext";
import { Button } from "primereact/button";
import { Password } from "primereact/password";
import { Divider } from "primereact/divider";
import { classNames } from "primereact/utils";
import { InputOtp } from "primereact/inputotp";
import AuthService from "./service";
import { Toast } from "primereact/toast";
import "./style.scss";
import { useNavigate } from "react-router-dom";
import axios from "axios";
import { ProgressSpinner } from "primereact/progressspinner";
let userEmail = "",
  userData = "";
function LogInpage() {
  const [showOtp, setShowOtp] = useState(false);
  const [token, setTokens] = useState("");
  const [forGotPassword, setForGotPassword] = useState(false);
  const toast = useRef(null);
  const [resendLoading, setResendLoading] = useState(false);
  const [resendVisibility, setResendVisibility] = useState(false);
  const navigate = useNavigate();

  const {
    control,
    setValue,
    formState: { errors },
    handleSubmit,
    reset,
    getValues,
  } = useForm({
    defaultValues: {
      name: "",
      email: "",
      password: "",
      date: null,
      country: null,
      accept: false,
    },
  });

  const [formValues, setFormValues] = useState(getValues());

  const customInput = ({ events, props }) => {
    return (
      <>
        <input
          {...events}
          {...props}
          type="text"
          className="custom-otp-input-sample"
        />
        {props.id === 2 && (
          <div className="px-3">
            <i className="pi pi-minus" />
          </div>
        )}
      </>
    );
  };

  const showWarn = (msg) => {
    toast.current.show({
      severity: "warn",
      summary: "Warning",
      detail: msg,
      life: 3000,
    });
  };

  // const resendCodeOnclick = () => {
  //   if (userData.name) {
  //     // setShowOtp(true);
  //     let userDataReqObj = {
  //       firstname: "",
  //       username: userData.name,
  //       password: "Test@123",
  //     };

  //     setResendLoading(true);

  //     AuthService.SendEmail(userDataReqObj).then((x) => {
  //       setResendLoading(false);
  //       if (x === "success") {
  //         setShowOtp(true);
  //         showSuccess("Another OTP has been sent to your email address");
  //       } else {
  //         setShowOtp(false);
  //         showWarn("User already exist. please log in.");
  //       }
  //     });
  //   }
  // };

  const showSuccess = (msg) => {
    toast.current.show({
      severity: "success",
      summary: "Email sent",
      detail: msg,
      life: 3000,
    });
  };

  const onSubmit = (data) => {
    userData = data;
    if (!data.name) {
      data.name = formValues.name;
    }
    if (data.name && data.password) {
      debugger;
      if (forGotPassword) {
        AuthService.updatePassword(data, data.password).then((x) => {
          debugger;
          if (x.includes("success")) {
            let loginDetails = {
              emailId: data.name,
              password: data.password,
              userPrivilege: x.split("$@$")[1],
            };
            localStorage.setItem(
              "sipSiteLoginDetails",
              JSON.stringify(loginDetails)
            );
            navigate("/");
          } else if (x === "no_user") {
            showWarn("User not exist. Please register.");
          } else {
            showWarn("Invalid username or password. Please check.");
          }
        });
      } else {
        AuthService.AllowUserLogin(data).then((x) => {
          if (x.includes("success")) {
            let loginDetails = {
              emailId: data.name,
              password: data.password,
              userPrivilege: x.split("$@$")[1],
            };
            localStorage.setItem(
              "sipSiteLoginDetails",
              JSON.stringify(loginDetails)
            );
            navigate("/");
          } else if (x === "no_user") {
            showWarn("User not exist. Please register.");
          } else {
            showWarn("Invalid username or password. Please check.");
          }
        });
      }
    }
  };

  const isValidEmailFormat = (email) => {
    // Regular expression for validating an email address
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    return emailRegex.test(email);
  };

  const forgotPassword = () => {
    const values = getValues();
    userData = values;
    console.log(values, "??V");
    if (values.name) {
      let isValidEmail = isValidEmailFormat(values.name);
      setShowOtp(true);
      if (isValidEmail) {
        axios
          .get("/isRegisteredUser?emailId=" + values.name)
          .then((isRegisteredUserResponse) => {
            if (isRegisteredUserResponse.data) {
              userEmail = values.name;

              // setResendLoading(true);
              // setResendVisibility(false);
              AuthService.ForgotPassword(values).then((x) => {
                if (x === "success") {
                  setResendLoading(false);
                  setResendVisibility(true);
                  showSuccess("OTP has been sent to your email address");
                  setShowOtp(true);
                } else {
                  setTokens("");
                }
              });
            } else {
              // setResendLoading(false);
              // setResendVisibility(false);
              toast.current.show({
                severity: "error",
                summary: "Email Id Not Found",
                detail: "Please register",
                life: 3000,
              });
            }
          });
      } else {
        // setResendLoading(false);
        // setResendVisibility(false);
        toast.current.show({
          severity: "error",
          summary: "Invalid Email Id Format",
          detail: "Please enter valid Email Id",
          life: 3000,
        });
      }
    } else {
      // setResendLoading(false);
      // setResendVisibility(false);
      toast.current.show({
        severity: "warn",
        summary: "Warning",
        detail: "Please enter your registered email Id",
        life: 3000,
      });
    }
  };

  const resendCodeOnclick = () => {
    const values = getValues();
    userData = values;
    console.log(values, "??V");
    if (values.name) {
      let isValidEmail = isValidEmailFormat(values.name);
      setShowOtp(true);
      if (isValidEmail) {
        axios
          .get("/isRegisteredUser?emailId=" + values.name)
          .then((isRegisteredUserResponse) => {
            if (isRegisteredUserResponse.data) {
              userEmail = values.name;

              setResendLoading(true);
              setResendVisibility(false);
              AuthService.ForgotPassword(values).then((x) => {
                if (x === "success") {
                  setResendLoading(false);
                  setResendVisibility(true);
                  showSuccess("OTP has been sent to your email address");
                  setShowOtp(true);
                } else {
                  setTokens("");
                }
              });
            } else {
              setResendLoading(false);
              setResendVisibility(false);
              toast.current.show({
                severity: "error",
                summary: "Email Id Not Found",
                detail: "Please register",
                life: 3000,
              });
            }
          });
      } else {
        setResendLoading(false);
        setResendVisibility(false);
        toast.current.show({
          severity: "error",
          summary: "Invalid Email Id Format",
          detail: "Please enter valid Email Id",
          life: 3000,
        });
      }
    } else {
      setResendLoading(false);
      setResendVisibility(false);
      toast.current.show({
        severity: "warn",
        summary: "Warning",
        detail: "Please enter your registered email Id",
        life: 3000,
      });
    }
  };

  const otpVerification = (data) => {
    if (data) {
      AuthService.otpReVerification(getValues(), data).then((x) => {
        if (x === true) {
          setShowOtp(false);
          setForGotPassword(true);
          debugger;
          console.log(userEmail);
          setFormValues({ ...formValues, name: userEmail });
        } else {
          setTokens("");
          showWarn("Wrong OTP");
        }
      });
    }
  };

  // const otpVerification = (data) => {
  //   debugger;
  //   console.log(data, ">> DATA");
  //   if (data && data.length === 6) {
  //     // setLoading(true);
  //     AuthService.otpVerification(control._formValues, data).then((x) => {
  //       // setLoading(false);
  //       if (x === true) {
  //         // control._fields.name._f.value
  //         // control._fields.password._f.value
  //         console.log(
  //           control._fields.name._f.value,
  //           "?? LOG",
  //           control._fields.password._f.value
  //         );
  //         let loginDetails = {
  //           emailId: control._fields.name._f.value,
  //           password: control._fields.password._f.value,
  //           userPrivilege: "normal",
  //         };
  //         localStorage.setItem(
  //           "sipSiteLoginDetails",
  //           JSON.stringify(loginDetails)
  //         );
  //         navigate("/");
  //       } else {
  //         setTokens(null);
  //         showWarn("Wrong OTP");
  //       }
  //     });
  //   }
  //   // reset();
  // };

  useEffect(() => {
    setForGotPassword(false);
    userEmail = "";
  }, []);

  return (
    <div className="sign-up-panel">
      <div className="sign-up-container">
        <Toast ref={toast} />
        <div className="left-panel"></div>
        <div className="right-panel">
          <div className="title-section">
            <h5>Welcome</h5>
            <h2>Login to your account</h2>
          </div>
          <div className="form-container">
            <div
              className={
                showOtp ? "field-container hide-form" : "field-container"
              }
            >
              <form onSubmit={handleSubmit(onSubmit)} className="p-fluid">
                <div className="field">
                  <label
                    htmlFor="name"
                    className={classNames({ "p-error": errors.name })}
                  >
                    Email
                  </label>
                  <Controller
                    name="name"
                    control={control}
                    disabled={forGotPassword}
                    rules={{ required: "Name is required." }}
                    render={({ field, fieldState }) => (
                      <InputText
                        id={field.name}
                        {...field}
                        autoFocus
                        className={classNames({
                          "p-invalid": fieldState.invalid,
                        })}
                      />
                    )}
                  />
                </div>
                <div className="field password-panel">
                  <label
                    htmlFor="password"
                    className={classNames({ "p-error": errors.password })}
                  >
                    {forGotPassword ? "New Password" : "Password"}
                  </label>
                  <Controller
                    name="password"
                    control={control}
                    rules={{ required: "Password is required." }}
                    render={({ field, fieldState }) => (
                      <Password
                        id={field.name} // Changed from field.password to field.name
                        {...field}
                        // autoFocus
                        feedback={false}
                        toggleMask
                        className={classNames({
                          "p-invalid": fieldState.invalid,
                        })}
                      />
                    )}
                  />
                  <span className="forgot-password" onClick={forgotPassword}>
                    Forgot password
                  </span>
                </div>
                <Button type="submit" label="Login" className="mt-2" />
              </form>
            </div>
            {showOtp && (
              <div className="field-container otp-container">
                <div className="flex flex-column align-items-center">
                  <p className="font-bold text-xl mb-2">
                    Authenticate Your Account
                  </p>
                  <p className="text-color-secondary block mb-5">
                    Please enter the code sent to your mail.
                  </p>
                  <InputOtp
                    value={token}
                    onChange={(e) => setTokens(e.value)}
                    length={6}
                    inputTemplate={customInput}
                    style={{ gap: 0 }}
                  />
                  <div className="flex justify-content-between align-self-stretch otp-buttons">
                    {/* <Button
                      label="Resend Code"
                      link
                      className="p-0"
                      // style={{ display: "none" }}
                      onClick={() => resendCodeOnclick()}
                      style={{ height: "10px" }}
                    ></Button> */}

                    <Button
                      // label={resendLoading ? null : "Resend Code"}
                      label={resendVisibility ? "Resend Code" : ""}
                      disabled={resendLoading}
                      link
                      className="p-0"
                      onClick={() => resendCodeOnclick()}
                      style={{ height: "10px" }}
                    >
                      {resendLoading && !resendVisibility && (
                        <ProgressSpinner
                          style={{ width: "1rem", height: "1rem" }}
                        />
                      )}
                    </Button>

                    <Button
                      label="Confirm"
                      onClick={() => otpVerification(token)}
                    ></Button>
                  </div>
                </div>
              </div>
            )}
            <Divider
              layout="horizontal"
              className="flex md:hidden"
              align="center"
            >
              <b>OR</b>
            </Divider>
            <Button
              type="button"
              label="Sign Up"
              className="mt-2 log-in-btn"
              onClick={() => navigate("/signinpage")}
            />
          </div>
        </div>
      </div>
    </div>
  );
}

export default LogInpage;
